import React from "react";

interface VideoComponentProps {
  videoSrc: string;
  ariaLabel: string;
  width?: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  videoSrc,
  ariaLabel,
  width = "400", // Set the default value for width here
}) => {
  return (
    <video
      width={width}
      className="articleCustomImage"
      controls
      aria-label={ariaLabel}
    >
      <source src={videoSrc} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoComponent;
