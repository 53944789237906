import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

interface MakerOSSectionProps {}

export default function MakerOSSection(props: MakerOSSectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.makerOSSectionRoot}>
      <Grid container lg={12}>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Typography className={classes.title}>
            Maker OS is an all-in-one productivity system for developers
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Typography className={classes.subtitle}>
            I built Maker OS to track, manage & organize my life. Now you can do
            it too!
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <div
            className={classes.videoiFrameContainer}
            onClick={() => {
              // gtag isn't available in localhost so using this workaround
              if (window && !window.location.href.includes("localhost")) {
                window.gtag(`event`, `click`, {
                  event_category: `engagement`,
                  event_label: "maker_os_video_play",
                });
              }
            }}
          >
            <LiteYouTubeEmbed
              id="hjvepM4uIHQ"
              title="MakerOS - The Ultimate Operating System for Makers, Creators and Developers based on Notion"
              iframeClass={classes.videoiFrameContainer}
              webp={true}
            />
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sm={12}
          className={classes.seeMoreButtonContainer}
        >
          <OutboundLink
            href="https://vinaygaba.gumroad.com/l/MakerOS?referrer=JetpackCompose.app"
            target="__blank"
          >
            <Typography className={classes.seeMoreButton}>
              <span className={classes.seeMoreButtonSpan}>Get it now</span>
            </Typography>
          </OutboundLink>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  makerOSSectionRoot: {
    borderRadius: 16,
    marginBottom: 32,
    border: "2px solid #222",
    padding: 16,
  },
  featuredJobsSectionRootGridContainer: {
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 24,
  },
  title: {
    textAlign: "center",
    color: "#000C2D",
    fontSize: "24px !important",
    fontFamily: "Playfair Display !important",
    fontWeight: important(700),
    paddingTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: important("16px"),
  },
  videoiFrameContainer: {
    position: "relative",
    maxWidth: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
    "& iFrame": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  subtitle: {
    textAlign: "center",
    color: "#000C2D",
    fontSize: 18,
    fontFamily: "Nunito Sans !important",
    fontWeight: 600,
    paddingLeft: important(16),
    paddingRight: important(16),
    marginBottom: important("16px"),
    // height: 0,
    // paddingBottom: "56.25%",
  },
  seeMoreButtonContainer: {
    paddingBottom: 32,
    paddingTop: 32,
    transition: "transform .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.03)",
    },
  },
  seeMoreButton: {
    textAlign: "center",
    color: "#FFF !important",
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    marginBottom: important("0px"),
  },
  seeMoreButtonSpan: {
    backgroundColor: "#4636f7",
    padding: 12,
    borderRadius: 8,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
});

function important<T>(value: T): T {
  return (value + " !important") as any;
}
