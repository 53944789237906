import React from "react";
import {
  GatsbyImage,
  StaticImage,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import { format } from "date-fns";

interface ArticleCardV2Props {
  title: string;
  dynamicImage?: any;
  description: string;
  date?: string;
  tags?: [string];
}

export default function ArticleCardV2(props: ArticleCardV2Props) {
  const dynamicImage: IGatsbyImageData | undefined = getImage(
    props.dynamicImage?.childImageSharp?.gatsbyImageData
  );
  const formattedDate = props.date
    ? format(new Date(props.date), "MMMM dd, yyyy")
    : null;

  return (
    <div className="rounded-xl overflow-hidden border transition-transform ease-in-out duration-300 hover:scale-[1.02]">
      <GatsbyImage
        image={dynamicImage as IGatsbyImageData}
        className="w-full object-cover aspect-video"
        alt="Hero Image"
      />
      <div className="px-6 py-4 font-inter font-normal">
        <div className="text-base mb-2 text-slate-600">{formattedDate}</div>
        <div className="text-2xl mb-2 text-black break-words">
          {props.title}
        </div>
        <div className="text-gray-700 text-lg">{props.description}</div>
      </div>
      {props.tags ? (
        <div className="px-6 pb-2">
          {props.tags?.map((tag) => (
            <span className="inline-block bg-slate-800 rounded-full px-3 py-1 text-sm font-medium text-white mr-2 mb-2 font-inter">
              {tag}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}
