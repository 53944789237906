import DonateRow from "../../../../src/components/articles/DonateRow";
import NewsletterRow from "../../../../src/components/newsletter/NewsletterRow";
import FeaturedJobsSection from "../../../../src/components/jobs/FeaturedJobsSection";
import MakerOSSection from "../../../../src/components/ads/MakerOSSection";
import VideoComponent from "../../../../src/components/videos/VideoComponent";
import * as React from 'react';
export default {
  DonateRow,
  NewsletterRow,
  FeaturedJobsSection,
  MakerOSSection,
  VideoComponent,
  React
};