import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React, { useEffect, useState } from "react";
import { shuffleArray } from "../../utils/ArrayUtils";
import FeaturedJobsCard from "./FeaturedJobsCard";

interface FeaturedJobsSectionProps {
  featuredJobs: any[];
}

export default function FeaturedJobsSection(props: FeaturedJobsSectionProps) {
  const classes = useStyles();
  const [randomJobs, setRandomJobs] = useState<any[]>([]);
  useEffect(() => {
    setRandomJobs(shuffleArray(props.featuredJobs));
  }, []);
  return (
    <div className={classes.featuredJobsSectionRoot}>
      <Grid container lg={12}>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Typography className={classes.title}>
            JetpackCompose.app Job Board
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Typography className={classes.subtitle}>
            Find the best Android jobs in world-class companies. Here are some
            featured jobs from the job board
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container className={classes.center}>
            {randomJobs.map((job) => {
              return (
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={4}
                  sm={6}
                  className={classes.featuredJobsSectionRootGridContainer}
                >
                  <FeaturedJobsCard
                    companyName={job.companyName}
                    companyLogoUrl={job.companyLogoUrl}
                    jobTitle={job.jobTitle}
                    jobDescription={job.jobDescription}
                    location={job.location}
                    jobPostUrl={job.jobPostUrl}
                    valueBadges={job.valueBadges}
                    key={job.jobPostUrl}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sm={12}
          className={classes.seeMoreButtonContainer}
        >
          <OutboundLink
            href="https://jetpackcompose.pallet.com/jobs"
            target="__blank"
          >
            <Typography className={classes.seeMoreButton}>
              <span className={classes.seeMoreButtonSpan}>See all jobs</span>
            </Typography>
          </OutboundLink>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  featuredJobsSectionRoot: {
    backgroundColor: "#E9F9F4",
    borderRadius: 16,
  },
  featuredJobsSectionRootGridContainer: {
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 24,
  },
  title: {
    textAlign: "center",
    color: "#000C2D",
    fontSize: 24,
    fontFamily: "Playfair Display",
    fontWeight: 700,
    paddingBottom: 24,
    paddingTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
  },
  subtitle: {
    textAlign: "center",
    color: "#000C2D",
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16,
  },
  seeMoreButtonContainer: {
    paddingBottom: 32,
    transition: "transform .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.03)",
    },
  },
  seeMoreButton: {
    textAlign: "center",
    color: "#FFF",
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
  },
  seeMoreButtonSpan: {
    backgroundColor: "#1F2A47",
    padding: 12,
    borderRadius: 8,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
});
