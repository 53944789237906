import React from "react";
import {
  GatsbyImage,
  StaticImage,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface TweetProps {
  name: string;
  username: string;
  userImage: any;
  tweetBody: string;
  tweetUrl?: string;
  tweetImage?: any;
}

const Tweet = (props: TweetProps) => {
  const content = (
    <TweetContent
      name={props.name}
      username={props.username}
      userImage={props.userImage}
      tweetBody={props.tweetBody}
      tweetUrl={props.tweetUrl}
      tweetImage={props.tweetImage}
    />
  );

  return props.tweetUrl ? (
    <OutboundLink href={props.tweetUrl} target="__blank">
      {content}
    </OutboundLink>
  ) : (
    <div>{content}</div>
  );
};

function TweetContent(props: TweetProps) {
  const userImage: IGatsbyImageData | undefined = getImage(
    props.userImage?.childImageSharp?.gatsbyImageData
  );
  return (
    <div className="rounded-xl overflow-hidden p-5 bg-slate-800 hover:bg-slate-900">
      <div className="flex flex-row">
        <div className="flex justify-center items-center">
          <div className="w-12 h-12 rounded-full">
            <GatsbyImage
              image={userImage as IGatsbyImageData}
              className="rounded-full"
              alt={props.name}
            />
          </div>
        </div>
        <div className="flex flex-col ml-2 flex-grow font-inter">
          <div className="text-white font-medium text-lg">{props.name}</div>
          <div className="text-gray-400 font-light">{`@` + props.username}</div>
        </div>
        {props.tweetUrl && (
          <div className="flex justify-center items-center">
            <div className="w-6 h-6">
              <StaticImage
                src="../images/twitter_logo.png"
                className=""
                alt="Twitter logo"
                placeholder="blurred"
                width={24}
                height={24}
                transformOptions={{ fit: "inside" }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="text-white pt-4 font-inter text-lg">
        {props.tweetBody}
      </div>
      {props.tweetImage ? (
        <div className="pt-4">
          <GatsbyImage
            image={getImage(props.tweetImage) as IGatsbyImageData}
            className="w-full object-scale-down rounded-xl"
            alt="Hero Image"
          />
        </div>
      ) : null}
    </div>
  );
}

export default Tweet;
