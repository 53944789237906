import Avatar from "@material-ui/core/Avatar";
import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Popover from "@material-ui/core/Popover";
import React from "react";
import Typography from "@material-ui/core/Typography";
import APIBadge from "./APIBadge";

enum ValueBadge {
  COMPOSE,
  WOMEN_LEADERSHIP,
  OPENSOURCE,
  API21,
  API22,
  API23,
  API24,
  API25,
  API26,
  API27,
  LGBTQ,
  FIRST,
  KOTLIN,
  DEFAULT,
}

interface FeaturedJobsCardProps {
  companyName: string;
  companyLogoUrl: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  jobPostUrl: string;
  valueBadges: string[];
}

export default function FeaturedJobsCard(props: FeaturedJobsCardProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState({
    target: null,
    badge: ValueBadge.DEFAULT,
  });
  const handlePopoverOpen = (event: any, badge: ValueBadge) => {
    setAnchorEl({
      target: event.currentTarget,
      badge: badge,
    });
  };
  const handlePopoverClose = () => {
    setAnchorEl({
      target: null,
      badge: ValueBadge.DEFAULT,
    });
  };
  const open = Boolean(anchorEl.target);

  function disableClick(event: any) {
    event.preventDefault();
  }

  return (
    <div className={classes.featuredJobsCardRoot}>
      <OutboundLink href={props.jobPostUrl} target="__blank">
        <div className={classes.featuredJobsCardContainer}>
          <Box width={1} className={classes.companyIconContainer}>
            <Avatar
              alt="Remy Sharp"
              src={props.companyLogoUrl}
              className={classes.companyIcon}
            />
          </Box>
          <Box width={1} className={classes.companyNameContainer}>
            <Typography className={classes.companyName}>
              {props.companyName}
            </Typography>
          </Box>
          <Box width={1} className={classes.jobTitleContainer}>
            <Typography className={classes.jobTitle}>
              {props.jobTitle}
            </Typography>
          </Box>
          <Box width={1} className={classes.descriptionContainer}>
            <Typography className={classes.description}>
              {props.jobDescription}
            </Typography>
          </Box>
          <Box width={1} className={classes.locationContainer}>
            <Typography className={classes.location}>
              <span className={classes.locationChip}>{props.location}</span>
            </Typography>
          </Box>
          <Box width={1} className={classes.valuesContainer}>
            {props.valueBadges.map((badge) => {
              const valueBadgeEnum = ValueBadge[badge];
              switch (valueBadgeEnum) {
                case ValueBadge.COMPOSE:
                case ValueBadge.WOMEN_LEADERSHIP:
                case ValueBadge.OPENSOURCE:
                case ValueBadge.FIRST:
                case ValueBadge.KOTLIN:
                  return (
                    <div
                      className={classes.valuesIcon}
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onClick={disableClick}
                      onTouchStart={(e) => handlePopoverOpen(e, valueBadgeEnum)}
                      onTouchEnd={handlePopoverClose}
                      onMouseEnter={(e) => handlePopoverOpen(e, valueBadgeEnum)}
                      onMouseLeave={handlePopoverClose}
                    >
                      <div className={classes.center}>
                        <img
                          src={getValueBadgeImageUrl(valueBadgeEnum)}
                          className={classes.valuesImage}
                        />
                      </div>
                    </div>
                  );
                case ValueBadge.API21:
                case ValueBadge.API22:
                case ValueBadge.API23:
                case ValueBadge.API24:
                case ValueBadge.API25:
                case ValueBadge.API26:
                case ValueBadge.API27:
                  return (
                    <div
                      className={classes.valuesIcon}
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onClick={disableClick}
                      onTouchStart={(e) => handlePopoverOpen(e, valueBadgeEnum)}
                      onTouchEnd={handlePopoverClose}
                      onMouseEnter={(e) => handlePopoverOpen(e, valueBadgeEnum)}
                      onMouseLeave={handlePopoverClose}
                    >
                      <APIBadge
                        version={badge.slice(-2)}
                        className={classes.valuesImage}
                      />
                    </div>
                  );
                default:
                  return <></>;
              }
            })}
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.popoverContainer,
              }}
              open={open}
              anchorEl={anchorEl.target}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography className={classes.popoverText}>
                {getBadgeDescription(anchorEl.badge)}
              </Typography>
            </Popover>
          </Box>
        </div>
      </OutboundLink>
    </div>
  );
}

function getValueBadgeImageUrl(valueBadge: ValueBadge): string {
  switch (valueBadge) {
    case ValueBadge.COMPOSE:
      return "/value_badge_compose.png";
    case ValueBadge.WOMEN_LEADERSHIP:
      return "/value_badge_women_leaders.png";
    case ValueBadge.OPENSOURCE:
      return "/value_badge_open_source.png";
    case ValueBadge.LGBTQ:
      return "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/313/rainbow_1f308.png";
    case ValueBadge.FIRST:
      return "/value_badge_first_hire.png";
    case ValueBadge.KOTLIN:
      return "/value_badge_kotlin.png";
    default:
      return "";
  }
}

function getBadgeDescription(valueBadge: ValueBadge): string {
  switch (valueBadge) {
    case ValueBadge.COMPOSE:
      return "Uses Jetpack Compose";
    case ValueBadge.WOMEN_LEADERSHIP:
      return "Has women leaders";
    case ValueBadge.OPENSOURCE:
      return "Contributes to open source";
    case ValueBadge.LGBTQ:
      return "LGBTQ Friendly";
    case ValueBadge.FIRST:
      return "First Android hire";
    case ValueBadge.KOTLIN:
      return "Uses Kotlin";
    case ValueBadge.API21:
    case ValueBadge.API22:
    case ValueBadge.API23:
    case ValueBadge.API24:
    case ValueBadge.API25:
    case ValueBadge.API26:
    case ValueBadge.API27:
      return `Min SDK Version ${ValueBadge[valueBadge].slice(-2)}`;
    default:
      return "";
  }
}

const useStyles = makeStyles({
  featuredJobsCardRoot: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid",
    borderRadius: 8,
    transition: "transform .3s ease-in-out",
    background: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.03)",
    },
    background: "#FFFFFF",
  },
  featuredJobsCardContainer: {
    padding: 16,
  },
  companyIconContainer: {
    paddingBottom: 16,
  },
  companyIcon: {
    height: 64,
    width: 64,
    border: "1px solid #222",
  },
  companyNameContainer: {
    paddingBottom: 8,
  },
  companyName: {
    color: "#222",
    fontSize: 24,
    fontFamily: "Nunito Sans",
    fontWeight: 900,
  },
  jobTitleContainer: {
    paddingBottom: 4,
  },
  jobTitle: {
    color: "#222",
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
  },
  descriptionContainer: {
    paddingBottom: 8,
  },
  description: {
    color: "#425466",
    fontSize: 14,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
  locationContainer: {},
  location: {
    color: "#FFF",
    fontSize: 14,
    fontFamily: "Nunito Sans",
    fontWeight: 400,
  },
  locationChip: {
    padding: 4,
    backgroundColor: "#0F1B35",
    borderRadius: 6,
  },
  valuesContainer: {
    display: "block",
    paddingTop: 8,
  },
  valuesIcon: {
    height: 24,
    width: 24,
    padding: 4,
    display: "inline-block",
    verticalAlign: "top",
  },
  valuesImage: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
  valuesFont: {
    fontSize: "100%",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverText: {
    fontSize: 14,
    color: "#222",
    fontFamily: "Nunito Sans",
    fontWeight: 600,
  },
  popoverContainer: {
    padding: 8,
  },
});
