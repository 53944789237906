import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Tweet from "../../tailwindComponents/Tweet";

interface TweetRowProps {
  name: string;
  username: string;
  tweetUrl: string;
  tweetBody: string;
  tweetImage?: any;
  userImage: any;
}

export default function TweetRow(props: TweetRowProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.tweetRowContainer}
    >
      <Grid item xs={12} md={6}>
        <Tweet
          name={props.name}
          username={props.username}
          tweetUrl={props.tweetUrl}
          tweetBody={props.tweetBody}
          tweetImage={props.tweetImage}
          userImage={props.userImage}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  tweetRowContainer: {
    marginBottom: 32,
  },
});
