import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

interface DonateRowProps {}

export default function DonateRow(props: DonateRowProps) {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <OutboundLink
        href="https://github.com/sponsors/vinaygaba"
        target="__blank"
      >
        <div className={classes.donateRowRoot}>
          <Grid container xs={12} lg={12}>
            <Grid item xs={12} lg={3} className={classes.center}>
              <StaticImage
                src="../../images/mo_coffee.png"
                className={classes.image}
                alt="Mascot Buy Me Coffee"
                placeholder="blurred"
                transformOptions={{ fit: "inside" }}
                width={150}
                height={150}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <Grid item xs={12} className={classes.supportButton}>
                ❤️ Support the site ➜
              </Grid>
              <Grid item xs={12} className={classes.description}>
                Learnt something new today? Thinking of a way to say "thank
                you"? <br /> <br />
                Consider supporting the site to continue getting unique
                insights, tips and tricks about Jetpack Compose. A cup of my
                favorite coffee goes a long way in helping me create new content
                😅
              </Grid>
            </Grid>
          </Grid>
        </div>
      </OutboundLink>
    </Container>
  );
}

const useStyles = makeStyles({
  donateRowRoot: {
    background: "#FFC864",
    borderRadius: 24,
    padding: 32,
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.03)",
    },
    transition: "transform .3s ease-in-out",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  image: {
    maxWidth: "100%",
    maxHeight: 200,
  },
  supportButton: {
    fontSize: 20,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    color: "#222",
    marginTop: 8,
    textTransform: "uppercase",
  },
  description: {
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: "normal",
    color: "#222",
    marginTop: 16,
  },
});
