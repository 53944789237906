import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

interface MarkdownVideoWrapperProps {
  videoId: string;
  videoTitle: string;
  videoThumbnail?: string
}

export default function MarkdownVideoWrapper(props: MarkdownVideoWrapperProps) {
  const thumbnailProp = props.videoThumbnail ? { thumbnail: props.videoThumbnail } : {};
  return (
    <div className="w-full flex justify-center items-center my-8">
      <div className="w-3/4">
        <LiteYouTubeEmbed
          id={props.videoId}
          title={props.videoTitle}
          webp={true}
          iframeClass="w-full"
          {...thumbnailProp}
        />
      </div>
    </div>
  );
}
