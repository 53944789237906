import { StandardProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";

type APIBadgeClassKey = "badge";

interface APIBadgeProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    APIBadgeClassKey
  > {
  version: string;
}

export default function APIBadge(props: APIBadgeProps) {
  const classes = useStyles();
  return (
    <div className={classes.componentNameRoot}>
      <Typography className={classes.version}>{props.version}</Typography>
    </div>
  );
}

const useStyles = makeStyles({
  componentNameRoot: {
    width: "90%",
    height: "90%",
    borderRadius: "50%",
    backgroundColor: "#000C2D",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  version: {
    fontSize: "80%",
    color: "#E9F9F4",
    fontWeight: 600,
    fontFamily: "Inter",
  },
});
