import React from "react";
import ArticleCardV2 from "./ArticleCardV2";
import { Link } from "gatsby";

interface MarkdownArticleCardWrapperProps {
  title: string;
  slugUrl: string;
  dynamicImage?: any;
  description: string;
  date?: string;
  tags?: [string];
}

export default function MarkdownArticleCardWrapper(
  props: MarkdownArticleCardWrapperProps
) {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="lg:w-1/2 md:w-3/5 sm: w-4/5">
        <Link to={props.slugUrl} partiallyActive={true}>
          <ArticleCardV2
            title={props.title}
            description={props.description}
            dynamicImage={props.dynamicImage}
            date={props.date}
            tags={props.tags}
          />
        </Link>
      </div>
    </div>
  );
}
