import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core";
import React, { useState, useEffect, FormEvent } from "react";
import { StaticImage } from "gatsby-plugin-image";

interface NewsletterRowProps {
  tagline?: React.ReactNode;
  invertColors?: boolean;
}

export default function NewsletterRow({
  tagline,
  invertColors = false,
}: NewsletterRowProps) {
  const classes = useStyles({ invertColors });
  const [signupSuccessful, setSignupSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const newsletterTagline = tagline || (
    <>
      Want Jetpack Compose related content & tools straight in your inbox <br />{" "}
      Subscribe for exclusive content and early access to content 👇
    </>
  );

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    try {
      const currentUrl = window.location.href;
      if (email.length && validEmail(email)) {
        setLoading(true);
        const formData = {
          email: email,
          referringSite: currentUrl,
          firstName: name.split(" ")[0] || "",
          lastName: name.split(" ")[1] || "",
        };
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result && result.success) {
          setTimeout(() => {
            setSignupSuccessful(true);
            setLoading(false);
          }, 2000);
        } else {
          setSignupSuccessful(false);
          setLoading(false);
        }
      }
    } catch (error) {
      setSignupSuccessful(false);
      setLoading(false);
    }
  };

  return (
    <div className={classes.newsletterRoot}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : !signupSuccessful ? (
        <>
          <Grid container className={classes.grid}>
            <Grid item xs={12} md={10}>
              <div className={classes.text}>{newsletterTagline}</div>
              <Grid
                container
                className={classes.grid}
                justify="space-around"
                alignItems="center"
              >
                <Grid item xs={12} md={5} className={classes.inputGrid}>
                  <TextField
                    id="name"
                    label="First Name"
                    className={classes.input}
                    variant="outlined"
                    color="primary"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    InputProps={{
                      className: classes.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5} className={classes.inputGrid}>
                  <TextField
                    id="newsletter-row-email-address"
                    label="Email Address"
                    className={classes.input}
                    variant="outlined"
                    error={!validEmail(email)}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    InputProps={{
                      className: classes.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    className={classes.subscribeButton}
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <StaticImage
                src="../../images/jetpack.png"
                className={classes.jetpackImage}
                alt="JetpackCompose.app Mascot"
                placeholder="blurred"
                width={200}
              />
            </Grid>
          </Grid>
          <div className={classes.subText}>
            No spam whatsoever and one click unsubscribe if you don't like it.
          </div>
        </>
      ) : (
        <>
          <div className={classes.text}>
            🎉 Thank you for joining us on the journey! Keen an eye out for an
            email from us 🎉{" "}
          </div>
        </>
      )}
    </div>
  );

  function validEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length === 0 || re.test(String(email).toLowerCase());
  }
}

export const useStyles = makeStyles<Theme, { invertColors: boolean }>(
  (theme: Theme) => ({
    newsletterRoot: {
      background: (props) => (props.invertColors ? "#222" : "#D6EFFE"), // Conditional background
      // background: "#FF8888",
      borderRadius: 8,
      padding: 32,
      marginLeft: 32,
      marginRight: 32,
      marginBottom: 32,
    },
    grid: {
      textAlign: "center",
      marginTop: 16,
    },
    text: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 20,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      textAlign: "center",
    },
    subText: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 14,
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      textAlign: "center",
      marginTop: 16,
    },
    form: {
      marginTop: 16,
    },
    inputGrid: {
      padding: 16,
    },
    input: (invertColors) => ({
      marginLeft: 16,
      width: "100%",
      ...(invertColors && {
        "& label.Mui-focused": {
          color: "#939393",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#939393",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#939393",
          },
        },
      }),
    }),
    inputLabelProps: {
      color: "#4636f7",
    },
    inputProps: {
      color: "#222",
      backgroundColor: "#FFF",
    },
    subscribeButton: {
      backgroundColor: "#4636f7 !important",
      color: "#FFFFFF",
      padding: 12,
      marginLeft: 16,
      "&:hover": {
        backgroundColor: "#3428b5",
      },
    },
    loading: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    jetpackImage: {
      width: "100%",
      maxWidth: 250,
      [theme.breakpoints.down("md")]: {
        marginTop: 16,
      },
    },
  })
);
